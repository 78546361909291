import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './Homepage';
import EcoWayInfoPage from './EcoWayInfo'; // Assume this is another component you have
import HealthWayInfo from './HealthWayInfo';
import DeepGuardInfoPage from './DeepGuardInfoPage';
//import Save from './save'
//import SaveS from './save copy'


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path='/deepguard-info'element={<DeepGuardInfoPage/>} />
        <Route path="/ecoway-info" element={<EcoWayInfoPage />} />
        <Route path="/healthway-info" element={<HealthWayInfo />} />

        {/* Define other routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;




