import React from 'react';
import './App.css';

// Removed the interface Props definition

const Journey = () => {
  return (
    <div>
      <h1 style={{textAlign:'center'}}>My Journey</h1>
      <p1>I am a computer science and economics student at the University of Virginia. I want to build cool things with cool people</p1>
      <br></br>
      <p style={{fontSize:'15pt'}}>nyanjprakash@gmail.com | linkedin.com/in/nyan-prakash</p>
      <br></br>
      <br></br>

    </div>
  );
};

export default Journey;
